// Customize this 'myform.js' script and add it to your JS bundle.
// Then import it with 'import MyForm from "./myform.js"'.
// Finally, add a <MyForm/> element whereever you wish to display the form.

import React from "react"

export default class MyForm extends React.Component {
  constructor(props) {
    super(props)
    this.submitForm = this.submitForm.bind(this)
    this.state = {
      status: "",
    }
  }

  render() {
    const { status } = this.state
    return (
      <form
        onSubmit={this.submitForm}
        action="https://formspree.io/f/mnqobobz"
        method="POST"
      >
        <p>
          <label>
            <span>Your name:</span>
            <input type="text" required name="name" placeholder="NAME" />
          </label>
        </p>
        <p>
          <label>
            <span>Your email:</span>
            <input type="email" required name="_replyto" placeholder="EMAIL" />
          </label>
        </p>
        <p>
          <label>
            <span>Your phone number:</span>
            <input type="text" name="phone" placeholder="PHONE NUMBER" />
          </label>
        </p>
        <p>
          <label>
            <span>Message:</span>
            <textarea name="message" placeholder="MESSAGE" rows="6" />
          </label>
        </p>
        {status === "SUCCESS" ? (
          <p className="small">Thank you! We will be in touch.</p>
        ) : (
          <button className="btn btn-outline-info" type="submit" value="Send">
            SUBMIT
          </button>
        )}
        {status === "ERROR" && (
          <p className="small">Ooops! There was an error. Please Try Again.</p>
        )}
      </form>
    )
  }

  submitForm(ev) {
    ev.preventDefault()
    const form = ev.target
    const data = new FormData(form)
    const xhr = new XMLHttpRequest()
    xhr.open(form.method, form.action)
    xhr.setRequestHeader("Accept", "application/json")
    xhr.onreadystatechange = () => {
      if (xhr.readyState !== XMLHttpRequest.DONE) return
      if (xhr.status === 200) {
        form.reset()
        this.setState({ status: "SUCCESS" })
      } else {
        this.setState({ status: "ERROR" })
      }
    }
    xhr.send(data)
  }
}
